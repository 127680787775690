import React, { useState } from "react";
import { Container, Row, Col, Button, Nav, Modal } from "react-bootstrap";
import ship from "../images/lulu-shipwreck.jpg";
import memorialReef from "../images/memorial-reef.jpg";
import floridaLimestone from "../images/florida-limestone.jpg";
import grouperReef from "../images/grouper-reef.jpg";
import superReef from "../images/super-reef.jpg";
import artficialReefs from "../images/artificial-reefs.jpg";
import superReefVideo from "../images/super-reef.mp4";
import floridaLimeStoneVideo from "../images/florida-reefs.mp4";
import shipVideo from "../images/boat-sinking.mp4";

// Improved YouTube URL with minimal parameters
const memorialReefVideo = "https://www.youtube.com/watch?v=mRqkkU0_0VM";

/**
 * YouTubeEmbed Component - Renders a responsive YouTube video iframe
 * @param {Object} props - Component props
 * @param {string} props.videoUrl - URL of the YouTube video
 * @param {string} props.title - Title for the iframe (for accessibility)
 * @returns {JSX.Element} - Rendered component
 */
const YouTubeEmbed = ({ videoUrl, title = "YouTube video" }) => {
  // Extract video ID from various YouTube URL formats
  const getYouTubeID = (url) => {
    if (!url) return null;

    // Handle different YouTube URL formats
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  const videoId = getYouTubeID(videoUrl);

  if (!videoId) {
    return <p>Invalid YouTube URL</p>;
  }

  return (
    <iframe
      width="100%"
      height="400"
      src={`https://www.youtube.com/embed/${videoId}`}
      title={title || "YouTube video player"}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
};

/**
 * Modal component for displaying detailed information about artificial reefs
 */
function ArtificialReefsModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Artificial Reefs
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>More about Artificial Reefs and the Marine Environment</h4>
        <p className="lead">
          In the northern Gulf of Mexico, a bland natural marine environment was
          transformed into a productive marine habitat by the installation of
          artificial reefs. Artificial reefs have created thousands of jobs and
          millions of pounds of demersal (bottom dwelling) fish for recreational
          and commercial fishermen. According to Northwest Florida an economic
          impact study from 2014 showed their artificial reef program employed
          over 8,000 people and brought in over $200 million in revenue. The
          Florida Sea Grant study in 2011 found that for every $1 spent on
          artificial reefs, $131 is added to the local yearly economy.
        </p>
        <p className="lead">
          Historically, there were very few demersal fish (red snapper, trigger,
          grouper fish etc.) in the northern Gulf of Mexico. This all changed
          when oil platforms followed by artificial reefs were created by the
          thousands.
        </p>
        <p className="lead">
          Alabama and Florida are the only states to develop a true private
          artificial reef program that allows local fishermen to build their own
          artificial reefs. No individual can own an artificial reef once it's
          placed on the bottom, but both states allow a measure of secrecy
          concerning the location.
        </p>
        <p className="lead">
          We entered the artificial reef business in Orange Beach, Alabama, in
          1986. In the beginning, most of our business was private reefs. We
          used feedback from hundreds of fishermen to create the most stable
          artificial reef habitat. Our patented design closely mimics coral
          reefs, can survive storm events, and produce the most targeted fish.
          After years of experimenting with different types of substrates, we
          learned that a certain Florida limestone rock possessed all the
          qualities that allowed every living thing on a natural reef to live on
          this rock. As we incorporated it into all our reef models, the results
          were incredible.
        </p>
        <p className="lead">
          Reefmaker is constantly experimenting with new designs and uses. We
          are dedicated to the future of saving, protecting, and growing our
          fisheries worldwide.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

// Styles
const lightSide = {
  background: "rgb(231, 246, 242)",
  color: "#2D4059",
};

const modalStyle = {
  background: "#2D4059",
  color: "rgb(231, 246, 242)",
};

// Artificial reef data
const artificialReefs = [
  {
    img: floridaLimestone,
    title: "Florida Limestone",
    price: "$2,695.00",
    description:
      "The first Florida Limestone reef was deployed in 2007 after three years of developing and testing. Florida Limestone rock is comprised of ancient clamshells that perfectly match a natural reef in PH and substrate. We developed a patented process to embed the rock into the concrete reef surface. The concrete by itself has a slightly different PH value than seawater providing a hostile environment for some marine life. Boring organisms that dwell on artificial reefs cannot survive on a concrete reef, but easily make our Florida Limestone reef their home. The Florida Limestone artificial reef is a 6,000 pound concrete structure built to withstand storm events.  The 10-foot footprint was designed to prevent settling and scouring. The limestone rock provides a greater surface area and the complexity it needs to attract marine life.",
    video: floridaLimeStoneVideo,
  },
  {
    img: grouperReef,
    title: "Grouper Reef",
    price: "$2,795.00",
    description:
      "Designed to mimic a low ledge that the Grouper fish prefer. Our Grouper reef is 7.3 feet x 6.8 feet wide and 5.6 feet tall. It has a special flanged base to help prevent subsidence. EcoSystem discs are added to the top which provides a complex reef for habitat and protection for juveniles and other animals found on the reef.",
  },
  {
    img: ship,
    title: "Ships",
    price: "Contact Us",
    description:
      "Walter Marine has prepared and deployed 24 ships to date. We do all of the cleaning which includes the removal and proper disposal of hazardous waste (PCB, Asbestos, hydrocarbons). Each ship is cleaned to the highest standards and furnished a chemist's certificate of cleanliness. The sinking is planned without the use of explosives to ensure the ship lands on the bottom upright and correctly oriented. We have deployed some of the largest vessels in the Gulf of Mexico. The most notable is The Lulu. The funding for this particular sinking was raised by a combined effort of the local community. There were even advertising signs painted onto the vessel with local businesses logos. Lulu's restaurant received the naming rights to the ship. They provided a barge for a concert with the Wet Willie band held for local boaters at the deployment site. Since explosives were not used boaters were invited to view up close the sinking.",
    video: shipVideo,
  },
  {
    img: memorialReef,
    title: "Memorial Reef",
    price: "$2,895.00",
    description:
      "What is a better way to remember your loved one than by creating an artificial reef in their honor? You can bring their ashes to us and we will mix them in the concrete to create an artificial reef. We can install a plaque and deploy the reef where you choose. It can be a public reef named after your loved one or a private reef for you and your family's own enjoyment. We do partner with the local crematorium and can offer complete services at a reasonable cost to our clients.",
    video: memorialReefVideo,
  },
  {
    img: superReef,
    title: "Super Reef",
    price: "Contact Us",
    description:
      "The largest artificial reef manufactured in the USA. It can be anywhere from 15 to 25 feet tall with the accessories added and weighs close to 16 tons. The Super Reef was designed to replicate the size and complexity of a ship when installed in groups.",
    video: superReefVideo,
  },
];

/**
 * ArtificialFeaturette Component - Displays information about artificial reefs
 * @returns {JSX.Element} - Rendered component
 */
const ArtificialFeaturette = () => {
  const [arModalShow, setArModalShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);

  return (
    <Container fluid className="py-2" style={lightSide}>
      <Container
        className="my-5 p-4 pb-0 pe-lg-0 align-items-center rounded-3 border shadow-lg"
        style={modalStyle}
        id="artificial-reefs"
      >
        <Row>
          <Col md={7} className="m-auto">
            <h2 className="fw-bold lh-1">
              Artificial Reefs and the Marine Environment
            </h2>
            <p className="lead">
              In the northern Gulf of Mexico, a bland natural marine environment
              was transformed into a productive marine habitat by the
              installation of artificial reefs. Artificial reefs have created
              thousands of jobs and millions of pounds of demersal (bottom
              dwelling) fish for recreational and commercial fishermen.
            </p>
            <Button
              variant="primary"
              onClick={() => setArModalShow(true)}
              className="mb-3"
            >
              Read More
            </Button>
            <ArtificialReefsModal
              show={arModalShow}
              onHide={() => setArModalShow(false)}
            />
          </Col>
          <Col md={5}>
            <img
              src={artficialReefs}
              className="bd-placeholder-img bd-placeholder-img-lg img-fluid mx-auto rounded-3"
              width={"500"}
              height={"500"}
              alt="Artificial reef structure with marine life"
            />
          </Col>
        </Row>
        <Row className="py-5 justify-content-center text-center">
          {artificialReefs.map((reef, index) => (
            <Col lg={4} className="pt-4" key={index}>
              <Nav.Link onClick={() => setLgShow(index)}>
                {reef.img ? (
                  <img
                    src={reef.img}
                    className="bd-placeholder-img rounded"
                    width={"140"}
                    height={"140"}
                    alt={`${reef.title} reef structure`}
                  />
                ) : (
                  <p>No image available for this reef.</p>
                )}
                <h3>{reef.title}</h3>
              </Nav.Link>

              <Modal
                size="lg"
                show={lgShow === index}
                onHide={() => setLgShow(false)}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>{reef.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col xs={12} md={reef.video ? 12 : 8}>
                        {reef.price === "Contact Us" ? (
                          <Button
                            href="#contact"
                            onClick={() => setLgShow(false)}
                            className="mb-2"
                          >
                            Contact Us
                          </Button>
                        ) : (
                          <p>Price: {reef.price}</p>
                        )}
                        <p>{reef.description}</p>
                      </Col>
                      {!reef.video && reef.img && (
                        <Col xs={6} md={4}>
                          <img
                            src={reef.img}
                            className="rounded"
                            width={"240"}
                            alt={`${reef.title} reef structure`}
                          />
                        </Col>
                      )}
                    </Row>
                    {reef.video && (
                      <Row className="mt-3">
                        <Col>
                          {typeof reef.video === "string" &&
                          (reef.video.includes("youtube.com") ||
                            reef.video.includes("youtu.be")) ? (
                            <YouTubeEmbed
                              videoUrl={reef.video}
                              title={`${reef.title} video`}
                            />
                          ) : (
                            <video
                              width="100%"
                              className="rounded"
                              controls
                              preload="metadata"
                              controlsList="nodownload"
                            >
                              <source src={reef.video} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          )}
                        </Col>
                      </Row>
                    )}
                  </Container>
                </Modal.Body>
              </Modal>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default ArtificialFeaturette;
