import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FloatingLabel,
  Button,
  Alert,
} from "react-bootstrap";

const styleLinks = {
  color: "#F6F6F6",
};

const Footer = () => {
  const [formLoaded, setFormLoaded] = useState(false);
  const [loadTime, setLoadTime] = useState(0);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [validated, setValidated] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    // Record when the form was loaded
    setLoadTime(Date.now());
    setFormLoaded(true);
  }, []);

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    // Basic form validation
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    // Time-based validation (if form is submitted in less than 3 seconds, likely a bot)
    const submissionTime = Date.now();
    if (submissionTime - loadTime < 3000) {
      setSubmitError(
        "Your submission was too quick. Please try again in a moment."
      );
      return;
    }

    // Check for typical spam patterns in the message
    const spamPatterns = [
      /https?:\/\//i, // URLs
      /\b(?:viagra|cialis|casino|poker|lottery|prize)\b/i, // Common spam words
      /\b(?:buy|cheap|free|discount|offer)\b.*\b(?:now|today|limited)\b/i, // Marketing phrases
    ];

    if (spamPatterns.some((pattern) => pattern.test(formValues.message))) {
      setSubmitError(
        "Your message contains content that appears to be spam. Please revise and try again."
      );
      return;
    }

    // Prevent double submission
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      // Create a hidden form to submit via Netlify's handler
      const netlifyForm = document.createElement("form");
      netlifyForm.setAttribute("method", "POST");
      netlifyForm.setAttribute("name", "contact");
      netlifyForm.setAttribute("data-netlify", "true");
      netlifyForm.setAttribute("hidden", "true");

      // Add required Netlify attributes
      const hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "form-name");
      hiddenField.setAttribute("value", "contact");
      netlifyForm.appendChild(hiddenField);

      // Add the actual form data
      Object.entries(formValues).forEach(([name, value]) => {
        const input = document.createElement("input");
        input.setAttribute("type", "text");
        input.setAttribute("name", name);
        input.setAttribute("value", value);
        netlifyForm.appendChild(input);
      });

      // Add the timestamp for server-side validation
      const timeInput = document.createElement("input");
      timeInput.setAttribute("type", "hidden");
      timeInput.setAttribute("name", "form-loaded-at");
      timeInput.setAttribute("value", loadTime.toString());
      netlifyForm.appendChild(timeInput);

      // Add honeypot field
      const honeypotInput = document.createElement("input");
      honeypotInput.setAttribute("type", "text");
      honeypotInput.setAttribute("name", "bot-field");
      honeypotInput.setAttribute("value", "");
      netlifyForm.appendChild(honeypotInput);

      // Append to body, submit, then remove
      document.body.appendChild(netlifyForm);
      netlifyForm.submit();

      // Show success and reset form
      setShowSuccess(true);
      setFormValues({ name: "", email: "", message: "" });
      setValidated(false);

      // Hide success message after 5 seconds
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    } catch (error) {
      setSubmitError(
        "There was a problem submitting your form. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container
      fluid
      style={{ background: "#2D4059" }}
      fixed="bottom"
      className="py-3"
    >
      <Container className="pt-4">
        <Row style={styleLinks}>
          {/* First three columns remain the same */}
          <Col>
            <h5>Reefmaker</h5>
            <p>
              David Walter <br /> Walter Marine <br /> PO Box 998 <br /> Orange
              Beach, AL 36561 <br />
            </p>
          </Col>
          <Col>
            <h5>About</h5>
            <a href="/#artificial-reefs">Artificial Reefs</a>
            <br />
            <a href="/#marine-ecosystems">Marine EcoSystems</a>
            <br />
            <a href="/photos-videos">Photos & Videos</a>
          </Col>
          <Col>
            <h5>Policy</h5>
            <p>Privacy Policy</p>
            <p>Cookie Policy</p>
            <p>California Policy</p>
          </Col>

          {/* Contact form column */}
          <Col id="contact">
            <div>
              <h5 className="text-white">
                Contact the Reefmaker/Walter Marine
              </h5>
              {submitError && <Alert variant="danger">{submitError}</Alert>}
              {showSuccess && (
                <Alert variant="success">
                  Your message has been sent successfully!
                </Alert>
              )}

              <Form
                method="POST"
                name="contact"
                data-netlify="true"
                netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
                noValidate
                validated={validated}
              >
                <input type="hidden" name="form-name" value="contact" />
                <div style={{ display: "none" }}>
                  <label>
                    Don't fill this out if you're human:{" "}
                    <input name="bot-field" />
                  </label>
                </div>
                {formLoaded && (
                  <input type="hidden" name="form-loaded-at" value={loadTime} />
                )}

                <FloatingLabel
                  controlId="name"
                  label="Full Name"
                  className="mb-3 text-dark lead"
                >
                  <Form.Control
                    type="text"
                    placeholder="John/Jane Doe"
                    name="name"
                    value={formValues.name}
                    onChange={handleChange}
                    required
                    minLength={2}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide your name.
                  </Form.Control.Feedback>
                </FloatingLabel>

                <FloatingLabel
                  controlId="email"
                  label="Email Address"
                  className="mb-3 text-dark lead"
                >
                  <Form.Control
                    type="email"
                    placeholder="email@domain.com"
                    name="email"
                    value={formValues.email}
                    onChange={handleChange}
                    required
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid email address.
                  </Form.Control.Feedback>
                </FloatingLabel>

                <FloatingLabel
                  controlId="message"
                  label="Message"
                  className="mb-3 text-dark lead"
                >
                  <Form.Control
                    as="textarea"
                    name="message"
                    placeholder="Leave a message"
                    style={{ height: "100px" }}
                    value={formValues.message}
                    onChange={handleChange}
                    required
                    minLength={10}
                    maxLength={1000}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a message (10-1000 characters).
                  </Form.Control.Feedback>
                </FloatingLabel>

                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Sending..." : "Submit"}
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
        <Row>
          <p className="text-center lead pt-3 mb-0" style={styleLinks}>
            © 1986 - {new Date().getFullYear()} Reefmaker - Walter Marine All
            Rights Reserved
          </p>
          <p className="text-center pb-3 lead" style={styleLinks}>
            Designed with{" "}
            <FontAwesomeIcon icon={faHeart} beat style={{ color: "red" }} />{" "}
            using{" "}
            <a
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white-50"
            >
              React
            </a>{" "}
            by{" "}
            <a
              href="https://terryhunt.dev"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white-50"
            >
              Terry Hunt
            </a>
          </p>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
